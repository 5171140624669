<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
// @ is an alias to /src
import {mapActions} from 'vuex'
export default {
  name: 'About',
  data(){
    return {
      
    }
  },
  components: {
    
  },
  mounted(){
    this.submit();
  },
  methods:{
    ...mapActions(['getTemplates']),
    submit(){
      this.getTemplates(this.formData).then(()=>{
          alert('done')
      }).catch(error => {
          alert('error')
      })
    }

  }
}
</script>